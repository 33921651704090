// Overwrite antd style globally
:root {
  --button-hover-color: #1890ff;
  // ... otras variables existentes ...
}
// 404 page
.ant-result {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-result-title {
  color: var(--white-text-color) !important;
}
.ant-result-subtitle {
  color: var(--white-text-color) !important;
}
.ant-form-item {
  margin-bottom: 0px !important;
  label {
    color: var(--white-text-color) !important;
  }
}

.ant-input {
  display: inline-block;
  border-radius: 5px !important;
  width: 100%;
  height: 40px;
  font-size: 12px !important;
  // text-overflow: ellipsis;
  color: var(--white-text-color) !important;
  background-color: inherit !important;
  padding: 0 12px !important;
  border: 1px solid var(--input-border-color) !important;
  font-family: var(--font-normal);
  font-weight: 400;
  &::placeholder {
    color: #8f90a6;
  }
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    background-color: var(--btn-disabled-color) !important;
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #8f90a6 !important;
}

.ant-select .ant-select-arrow {
  color: rgba(255, 255, 255, 0.25);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 0 12px !important;
  font-size: 12px !important;
  background-color: inherit !important;
  border: 1px solid var(--input-border-color) !important;
  color: white;
  border-radius: 5px;
  box-shadow: unset !important;
}
.ant-select-dropdown .ant-select-item-option-content {
  color: var(--white-text-color) !important;
}

.ant-select-dropdown {
  background-color: var(--dropdown-bg-color) !important;
  border-radius: 0px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}

/* antd single selector first empty value style  */
span.ant-select-selection-item[title~="Select"] {
  color: #b9b9b9;
}

.ant-select-item-option-content {
  font-size: 12px;
}

.ant-select-item-option {
  background-color: #2f3047;
  color: white;
  border-radius: 0px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #555770 !important;
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #555770 !important;
  color: var(--white-text-color) !important;
  border: none !important;
}
.ant-select-selection-placeholder {
  color: #8f90a6 !important;
  margin-top: 4px;
}

.ant-divider-horizontal {
  border-top-color: #555770;
}

// upload file

.ant-upload-list {
  color: var(--white-text-color);
}

.ant-upload-list-item-name {
  color: var(--white-text-color);
}

.ant-upload-list-item-name:hover {
  color: var(--white-text-color);
}

.ant-upload-list .ant-upload-list-item:hover {
  background-color: transparent !important;
}

.ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon {
  color: var(--white-color) !important;
}

.ant-upload-list-item-actions {
  button {
    color: var(--white-text-color) !important;
    background-color: transparent !important;
    border: none !important;
    svg {
      color: var(--white-text-color) !important;
    }
  }

  &:hover {
    color: white !important;
    background-color: transparent !important;
    svg {
      color: white !important;
    }
  }
}
.ant-upload-list-text .ant-upload-list-item-name {
  flex: none !important;
  width: auto !important;
}

.ant-upload-span .ant-upload-text-icon span {
  color: white !important;
}

.ant-radio-wrapper span {
  color: #e4e4eb !important;
}

.ant-radio-wrapper .ant-radio-inner {
  background-color: transparent !important;
}

.ant-radio-checked .ant-radio-inner {
  border: 1px solid #00d100 !important;
  background-color: transparent !important;
}

.ant-radio-inner::after,
.ant-radio-disabled .ant-radio-inner::after {
  background-color: #00d100 !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #00d100;
}

.ant-radio-wrapper {
  margin-right: 10px;
}

.ant-input-number {
  background: transparent;
  border: 1px solid var(--input-border-color) !important;
  border-radius: 5px !important;
}

.ant-input-number .ant-input-number-input {
  color: white !important;
}

.ant-input-number-handler-wrap {
  background: transparent !important;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner,
.ant-input-number-prefix {
  color: white !important;
}

.ant-collapse {
  background-color: #32334d !important;
  border-radius: var(--border-radius) !important;
  /* border: 1px solid #7e7e7e !important; */
  /* border: 1px solid var(--grey-border-color) !important; */
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--white-text-color) !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  color: var(--white-text-color) !important;
  background-color: #32334d !important;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  /* border: 1px solid var(--grey-border-color) !important; */
  border: 1px solid var(--grey-border-color);
}

.ant-space-vertical {
  width: 100%;
}

.ant-collapse-content {
  background-color: unset !important;
  border-top: unset !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 20px 50px;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 4px !important;
}

.ant-collapse > .ant-collapse-item-active:last-child > .ant-collapse-header {
  border-radius: 4px 4px 0px 0px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-top: 9px !important;
}

.custom-tree {
  background-color: transparent;
  color: white; 
}

.custom-tree .ant-tree-node-disabled .ant-tree-node-content-wrapper {
  color: red; 
}
/* Estilos base para botones */
.btn.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.btn.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;

  &:hover {
    background-color: #1890ff !important;
    border-color: #1890ff !important;
    color: white !important;
  }
}

.ant-btn {
  /* Asegurarse que los estilos de Ant Design no interfieran */
  &:hover,
  &:focus,
  &:active {
    background-color: inherit;
    color: inherit;
  }
}
.ant-spin-nested-loading .ant-spin-blur {
  opacity: 0.2 !important ;
}
